import React from 'react';
import {Modal, Button} from 'react-bootstrap';

import './ModalHome.css';
import logo from '../../images/logo-long.png';
import InputOpenDiagram from "../InputOpenDiagram";

const ModalHome = ({handleOnCreateNew, onHide = () => {}, ...props}) => {
  const onCreateNew = () => {
    handleOnCreateNew();
    onHide();
  };

  return (
    <Modal
      {...props}
      onHide={onHide}
      size="sm"
      backdrop="static"
      className='modal-home'
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        className="modal-home-header"
      >
        <Modal.Title
          className="modal-home-title"
          id="contained-modal-title-vcenter"
        >
          <img alt='logo' width={160} height={39} src={logo} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-home-body'>
        <Button variant='warning' onClick={onCreateNew}>Create new Diagram</Button>

        <InputOpenDiagram
          id='input_modal_file'
          onStartLoad={onHide}
        >
          <Button variant='warning'>Open existing Diagram</Button>
        </InputOpenDiagram>

      </Modal.Body>
    </Modal>
  );
}

export default ModalHome;
