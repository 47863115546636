import React, {useContext} from 'react';

import {ContextSchema, getHighestId, lineTypes} from "../shared";

const InputOpenDiagram = ({
  id = 'input_file_default', // Must be unique
  onFileLoaded = () => {},
  onStartLoad = () => {},
  children
}) => {
  const {
    reactFlowInstance,
    setIsLoadingDiagram,
    setLineType,
    onIncrementId,
    setElements
  } = useContext(ContextSchema);

  const onLoadDiagram = () => {
    document.getElementById(id).click();
    setIsLoadingDiagram(true);
  };

  const fileInputClicked = (event) => {
    const target = event.target;
    if (target.value.length === 0) {
      setIsLoadingDiagram(false); //  Suspect Cancel was hit, no files selected
    } else {
      onStartLoad();
      const fileReader = new FileReader();
      fileReader.readAsText(target.files[0], "UTF-8");
      fileReader.onload = (evt) => {
        const result = JSON.parse(evt.target.result);

        const maxId = getHighestId(result); // Get highest node id

        setElements(result.elements); // Pass new list of elements
        onIncrementId(maxId); // Need to set new ID count

        if (result.lineType) {
          setLineType(result.lineType);
        } else {
          setLineType(lineTypes.smooth);
        }

        setTimeout(() => {
          reactFlowInstance.fitView();
          setIsLoadingDiagram(false);
          onFileLoaded();
        }, 500);
      };
    }
    window.removeEventListener('focus', handleFocusBack);
  };

  const handleFocusBack = () => {
    setTimeout(() => {
      setIsLoadingDiagram(false);
    }, 500);
    window.removeEventListener('focus', handleFocusBack);
  };

  const clickedFileInput = (e) => {
    e.target.value = null; // reset potential value
    window.addEventListener('focus', handleFocusBack);
  };

  return (
    <div onClick={onLoadDiagram}>
      <input
        id={id}
        type='file'
        accept='.json'
        onChange={fileInputClicked}
        onClick={clickedFileInput}
        hidden
      />
      {children}
    </div>
  );
};

export default InputOpenDiagram;
