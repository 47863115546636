import React, {memo, useContext, useEffect, useRef, useState} from 'react';
import {Resizable} from 're-resizable';
import {useStoreState} from 'react-flow-renderer';

import './ResizeNode.css';
import {ContextSchema} from "../shared";

const ResizeNode = memo(({ onRemove, data, id }) => {
  const [watt, setWatt] = useState(data?.desc ?? "Click to edit");
  const [editLabel, setEditLabel] = useState(false);
  const [height, setHeight] = useState(data?.height ?? 100);
  const [width, setWidth] = useState(data?.width ?? 300);
  const inputRef = useRef();
  const nodes = useStoreState((state) => state.nodes);
  const {elements, setElements} = useContext(ContextSchema);

  useEffect(() => {
    if (editLabel) {
      inputRef.current.focus();
    }
  }, [editLabel]);

  useEffect(() => {
    setElements(elements.map((el) => {
      if (el.id === id) {
        el.data["width"] = width;
        el.data["height"] = height;
      }
      return el;
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height])

  const onResizeStop = (e, direction, ref, d) => {
    setHeight((prev) => prev + d.height);
    setWidth((prev) => prev + d.width);
  };

  return (
    <Resizable
      className='resize-node'
      size={{ width, height }}
      onResizeStop={onResizeStop}
      handleClasses={{
        top: "nodrag",
        right: "nodrag",
        bottom: "nodrag",
        left: "nodrag",
        topRight: "nodrag",
        bottomRight: "nodrag",
        bottomLeft: "nodrag",
        topLeft: "nodrag"
      }}
    >
      <div
        className='node-remove'
        onClick={() => {
          const el = nodes.find((e) => e.id === id);
          return onRemove([el]);
        }}
      >
        X
      </div>
      {
        !editLabel
          ? (
            <label
              className='resize-node-input-label'
              style={{width: width + 'px'}}
              onClick={() => setEditLabel(true)}
            >
              {watt}
            </label>
          )
          : (
            <input
              className='resize-node-input-label'
              style={{width: width + 'px'}}
              type="text"
              ref={inputRef}
              onChange={(e) => {
                setWatt(e.target.value);
                setElements(elements.map((el) => {
                  if (el.id === id) {
                    el.data["desc"] = e.target.value;
                  }
                  return el;
                }));
              }}
              onBlur={() => {
                setEditLabel(false);
              }}
              value={watt}
            />
          )
      }
    </Resizable>
  );
});

const ResizeNodeWrapper = (onRemove) => (props) => {
  return (
    <ResizeNode {...props} onRemove={onRemove} />
  );
};

export default ResizeNodeWrapper;
