import React, {memo, useRef, useContext, useEffect, useState} from 'react';
import {
  getMarkerEnd,
  getSmoothStepPath,
  getBezierPath
} from 'react-flow-renderer';

import './CustomEdge.css';
// import {getCustomStepPath} from "./getCustomStepPath";
import {ContextSchema, lineTypes} from "../shared";

const foreignObjectSize = 40;

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
  onElementsRemove
}) => {
  const pathRef = useRef();
  const [pt, setPt] = useState();
  const [stylePath, setStylePath] = useState({
    strokeWidth: style.strokeWidth || 5,
    stroke: style.stroke || "#ff0000"
  });
  const {lineType, elements, setElements} = useContext(ContextSchema);

  useEffect(() => {
    if (pathRef.current) {
      const pth = document.querySelector(".path" + id);
      if (pth) {
        const lgt = pth.getTotalLength();
        const pts = pth.getPointAtLength(Math.floor(lgt/2));
        pts.x = Math.round(pts.x);
        pts.y = Math.round(pts.y);
        setPt(pts);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineType, pathRef])

  const onEdgeClick = (evt, id) => {
    evt.stopPropagation();
    onElementsRemove(evt, id);
  };

  const edgePath = lineType === lineTypes.smooth
    ? getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
      })
    : getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  const onClickPathToggle = () => {
    const newColor = stylePath.stroke === "#ff0000" ? "#000000" : "#ff0000";
    const newStyle = {...stylePath, stroke: newColor };
    setStylePath(newStyle);

    const newElement = elements.map((el) => {
      if (el.id === id) {
        el.style = newStyle;
      }
      return el;
    });
    setElements(newElement);
  };

  return (
    <>
      <path
        id={id}
        ref={pathRef}
        style={stylePath}
        className={`react-flow__edge-path customedge path${id}`}
        d={edgePath}
        markerEnd={markerEnd}
        onClick={onClickPathToggle}
      />

      {
        pt && pt.x
        ? (
            <foreignObject
              width={foreignObjectSize}
              height={foreignObjectSize}
              x={pt.x - foreignObjectSize / 2}
              y={pt.y - foreignObjectSize / 2}
              className="edgebutton-foreignobject"
              requiredExtensions="http://www.w3.org/1999/xhtml"
            >
              <div>
                <button
                  className="edgebutton"
                  onClick={(event) => onEdgeClick(event, id)}
                >
                  ×
                </button>
              </div>
            </foreignObject>
          )
          : null
      }
    </>
  );
};

const CustomEdgeWrapper = (onElementsRemove) => memo((props) => {
  return (
    <CustomEdge {...props} onElementsRemove={onElementsRemove} />
  )
})

export default CustomEdgeWrapper;
