import React, {useEffect} from 'react';
import logo from '../images/logo-long.png';

import './IfMobile.css';

const homeElement = document.querySelector('.spdBlock');

const IfMobile = () => {
  useEffect(() => {
    if (homeElement) {
      homeElement.remove();
    }
  }, [])
  return (
    <div className='dnd-if-mobile'>
      <img
        alt='Solar Panel Diagram'
        src={logo}
        width={298}
        height={70}
      />
      <h4>This app is not available on mobile</h4>
      <p>
        Check others tools on
        <br/><a href='https://solarpowertoolbox.com'>https://solarpowertoolbox.com</a>
      </p>
    </div>
  );
}

export default IfMobile;
