import React, { useEffect, useState } from 'react';
import ReactFlow, {
  Background,
  ReactFlowProvider,
  addEdge,
  removeElements,
  Controls
} from 'react-flow-renderer';

import Sidebar from './Sidebar';

import PanelNode from "./PanelNode";
import InverterNode from "./InverterNode";
import BatteryNode from "./BatteryNode";
import BatteryFuseNode from "./BatteryFuseNode";
import BusBarNode from "./BusBarNode";
import CableEntryGlandNode from "./CableEntryGlandNode";
import CircuitBreakerNode from "./CircuitBreakerNode";
import ElectricBreakerNode from "./ElectricBreakerNode";
import MpptNode from "./MpptNode";
import PwmNode from "./PwmNode";
import GroundNode from "./GroundNode";
import SwitchNode from "./SwitchNode";
import Switch4Node from "./Switch4Node";
import ResizeNode from "./ResizeNode";
import TextNode from "./TextNode";
import VoltageNode from "./VoltageNode";
import InlineFuseNode from "./InlineFuseNode";
import DcLoadNode from "./DcLoadNode";
import AcLoadNode from "./AcLoadNode";
import SolarGeneratorNode from "./SolarGeneratorNode";
import UsbLoadNode from "./UsbLoadNode";
import DcDcNode from "./DcDcNode";
import CustomEdge from "./CustomEdge";

import ModalHome from "./ModalHome";
import LoadingScreens from "./LoadingScreens";

import {
  ContextSchema, getHighestId,
  lineTypes,
  saveJson
} from "./shared";

import './dnd.css';

const initialElements = [];

const onDragOver = (event) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'move';
};

let id = 0;
const incrementId = (num) => {
  id = num;
};

const homeElement = document.querySelector('.spdBlock');

const DnDFlow = () => {
  const [reactFlowInstance, setReactFlowInstance] = useState();
  const [elements, setElements] = useState(initialElements);
  const [isLoadingDiagram, setIsLoadingDiagram] = useState(false);
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [openWelcome, setOpenWelcome] = useState(true);

  const [lineType, setLineType] = useState(lineTypes.smooth);

  useEffect( () => {
    if (homeElement) {
      homeElement.remove();
    }
    // Check to load demo data
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (reactFlowInstance && params && params.demo) {

      async function checkAndLoad() {
        let demoJson;

        switch (params.demo) {
          case '1': {
            const jsonData = await import('../json/solar-diagram-style.json');
            demoJson = jsonData.default;
            break;
          }

          default: {
            demoJson = null;
            break;
          }
        }

        if (demoJson) {
          const maxId = getHighestId(demoJson); // Get highest node id
          incrementId(maxId); // Need to set new ID count
          setElements(demoJson.elements);
          setTimeout(() => {
            reactFlowInstance.fitView();
          }, 300);
        }
      }

      checkAndLoad();
    }
  }, [reactFlowInstance]);

  const onConnect = (params) => {
    if (params.source === params.target) {
      return null;
    }
    return setElements((els) => addEdge({...params, type: 'buttonedge'}, els));
  }
  const onElementsRemove = (elementsToRemove) => setElements((els) => removeElements(elementsToRemove, els));
  const onEdgeRemove = (elementsToRemove, idElement) => setElements((els) => {
    const el = els.find((e) => e.id === idElement);
    return removeElements([el], els);
  });
  const onLoad = (_reactFlowInstance) => setReactFlowInstance(_reactFlowInstance);
  const onDrop = (event) => {
    event.preventDefault();

    if (reactFlowInstance) {
      const type = event.dataTransfer.getData('application/reactflow');
      const position = reactFlowInstance.project({ x: event.clientX - 220, y: event.clientY - 30 });
      id++; // Increment ID
      const newNode = {
        id: `dndnode_${id}`,
        type,
        position,
        data: { label: `${type} node` },
      };
      setElements((es) => es.concat(newNode));
    }

  };
  const onToggleLine = (type) => {
    setLineType(type);
  };
  const onSaveDiagram = () => {
    const storageObj = reactFlowInstance.toObject();
    storageObj.lineType = lineType; // Add line type
    saveJson(storageObj);
  };
  const onCreateNewDiagram = () => {
    id = 0; // Reset id count
    setElements(initialElements);
  };

  const nodeTypes = {
    panel: PanelNode(onElementsRemove),
    inverter: InverterNode(onElementsRemove),
    mppt: MpptNode(onElementsRemove),
    pwm: PwmNode(onElementsRemove),
    battery: BatteryNode(onElementsRemove),
    batteryfuse: BatteryFuseNode(onElementsRemove),
    cableentrygland: CableEntryGlandNode(onElementsRemove),
    circuitbreaker: CircuitBreakerNode(onElementsRemove),
    electricbreaker: ElectricBreakerNode(onElementsRemove),
    busbar: BusBarNode(onElementsRemove),
    ground: GroundNode(onElementsRemove),
    switch: SwitchNode(onElementsRemove),
    switch4: Switch4Node(onElementsRemove),
    resize: ResizeNode(onElementsRemove),
    text: TextNode(onElementsRemove),
    voltage: VoltageNode(onElementsRemove),
    inlinefuse: InlineFuseNode(onElementsRemove),
    dcload: DcLoadNode(onElementsRemove),
    acload: AcLoadNode(onElementsRemove),
    solargenerator: SolarGeneratorNode(onElementsRemove),
    usbload: UsbLoadNode(onElementsRemove),
    dcdc: DcDcNode(onElementsRemove)
  };

  const edgeTypes = {
    buttonedge: CustomEdge(onEdgeRemove),
  };

  return (
    <div className="dndflow">
      <LoadingScreens
        isLoadingDiagram={isLoadingDiagram}
        isLoadingImg={isLoadingImg}
        isLoadingPDF={isLoadingPDF}
      />

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a id="downloadAnchorElem" style={{display: 'none'}}>Make ES happy</a>

      <ContextSchema.Provider
        value={{
          lineType,
          reactFlowInstance,
          elements,
          setElements,
          setLineType: onToggleLine,
          setIsLoadingDiagram,
          onIncrementId: incrementId
        }}
      >
        <ModalHome
          onHide={() => setOpenWelcome(false)}
          show={openWelcome}
          handleOnCreateNew={onCreateNewDiagram}
        />

        <ReactFlowProvider>
          <div className="reactflow-wrapper">
            <ReactFlow
              elements={elements}
              edgeTypes={edgeTypes}
              onConnect={onConnect}
              onElementsRemove={onElementsRemove}
              onLoad={onLoad}
              onDrop={onDrop}
              onDragOver={onDragOver}
              nodeTypes={nodeTypes}
              connectionMode='loose'
              snapToGrid
              snapGrid={[20,20]}
            >
              <Background
                variant="dots"
                gap={20}
                size={0.75}
              />
              <Controls />
            </ReactFlow>
          </div>
          <Sidebar
            onSave={onSaveDiagram}
            onToggleLine={onToggleLine}
            onCreateNewDiagram={onCreateNewDiagram}
            setIsLoadingPDF={setIsLoadingPDF}
            setIsLoadingImg={setIsLoadingImg}
            onIncrementId={incrementId}
          />
        </ReactFlowProvider>
      </ContextSchema.Provider>
    </div>
  );
};

export default DnDFlow;
