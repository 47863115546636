import isMobile from 'ismobilejs';

import DnDFlow from './Diagram/DnDFlow';
import IfMobile from './IfMobile';

import './App.css';

function App() {
  return (
    <div className="App">
      {
        isMobile(window.navigator).any
        ? <IfMobile />
        : <DnDFlow />
      }

    </div>
  );
}

export default App;
