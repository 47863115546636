import {getHighestId, lineTypes} from "../shared";

export const listDemos = {
  " The mini for RV - 200W 12V" : "solar-diagram-200w-12v.json",
  " Great for RV - 400W 12V" : "solar-diagram-400w-12v.json",
  " Powerful - 400/2000W 24V": "solar-diagram-2000w.json",
};

export async function loadJsonDiagram(path, reactFlowInstance, setLineType, onIncrementId, setElements) {
  let demoJson;
  const jsonData = await import(`../../json/${path}`);
  demoJson = jsonData.default;

  if (demoJson) {
    const maxId = getHighestId(demoJson); // Get highest node id
    onIncrementId(maxId); // Need to set new ID count
    setElements(demoJson.elements);

    const line = demoJson?.lineType || lineTypes.smooth;
    setLineType(line);

    setTimeout(() => {
      reactFlowInstance.fitView();
    }, 300);
  }
}
