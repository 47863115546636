import { createContext } from 'react';

import LogoLong from "../images/logo-long.png";

export const lineTypes = {
  smooth: "smooth",
  bezier: "bezier"
};

export const resolveAfter = (ms) => new Promise(resolve => {
  setTimeout(() => {
    resolve('resolved');
  }, ms);
});

export const getHighestId = (jsonData) => {
  let maxId = 0; // Get highest node id
  jsonData.elements.forEach((el) => {
    if (el.hasOwnProperty('position')) {
      const idToCheck = el.id.split('_')[1];
      maxId = Math.max(maxId, Number(idToCheck));
    }
  });
  return maxId;
}

export const saveJson = (storageObj) => {
  const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(storageObj));
  const dlAnchorElem = document.getElementById('downloadAnchorElem');
  dlAnchorElem.setAttribute("href",     dataStr     );
  dlAnchorElem.setAttribute("download", "solar-diagram.json");
  dlAnchorElem.click();
}

export const pdfOptions = {
  jsPDF: {
    format: 'letter',
  },
  html2canvas: {
    y: -100, // Margin top
    ignoreElements: (element) => element.classList.contains('edgebutton'),
  },
  watermark: {
    src: LogoLong,
    handler({ pdf, imgNode }) {
      const {width, height} = pdf.getImageProperties(imgNode);
      const {width: pWidth, height: pHeight} = pdf.internal.pageSize;
      const imgW = pWidth/4;
      const imgH = imgW  * height / width;
      let xImg = (pWidth - imgW) / 2;

      pdf.setFillColor( 250,250,250 );
      pdf.rect(0, 0, pWidth,  imgH + 5, 'F');

      pdf.addImage(imgNode, 'PNG', xImg, 2, imgW, imgH);

      pdf.setTextColor('#ddd');
      pdf.setFontSize(10);
      pdf.text(30, pHeight - 15, `Diagram designed with SolarPowerDiagram.com`);
    },
  },
  imageType: 'image/jpeg',
  output: 'solar-diagram.pdf'
};

export const iconStyles = {height:20, width:20};

export const ContextSchema = createContext();
