import React, {memo, useContext, useEffect, useRef, useState} from 'react';

import {Handle, useStoreState} from 'react-flow-renderer';

import './CircuitBreakerNode.css';
import {ContextSchema} from "../shared";

const connectorStyle = {
  background: '#fff',
  border: '2px solid black',
  width: '15px',
  height: '15px'
};

const CircuitBreakerNode = memo(({ onRemove, data, id, isConnectable }) => {
  const [watt, setWatt] = useState(data?.desc ?? "100A");
  const [editLabel, setEditLabel] = useState(false);
  const inputRef = useRef();
  const nodes = useStoreState((state) => state.nodes);
  const {elements, setElements} = useContext(ContextSchema);

  useEffect(() => {
    if (editLabel) {
      inputRef.current.focus();
    }
  }, [editLabel]);

  return (
    <div className='circuitbreaker-node'>
      <div
        className='node-remove'
        onClick={() => {
          const el = nodes.find((e) => e.id === id);
          return onRemove([el]);
        }}
      >
        X
      </div>
      {
        !editLabel
          ? (
            <label
              className='circuitbreaker-node-input-label'
              onClick={() => {
                setEditLabel(true);
              }}
            >
              {watt}
            </label>
          )
          : (
            <input
              className='circuitbreaker-node-input-label'
              type="text"
              ref={inputRef}
              onChange={(e) => {
                setWatt(e.target.value);
                setElements(elements.map((el) => {
                  if (el.id === id) {
                    el.data["desc"] = e.target.value;
                  }
                  return el;
                }));
              }}
              onBlur={() => {
                setEditLabel(false);
              }}
              value={watt}
            />
          )
      }


      <Handle
        type="source"
        id="a"
        position="left"
        style={{ ...connectorStyle, top: 72, left: 5 }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id="b"
        position="right"
        style={{ ...connectorStyle, top: 9, left: 36 }}
        isConnectable={isConnectable}
      />

    </div>
  );
});

const CircuitBreakerNodeWrapper = (onRemove) => (props) => {
  return (
    <CircuitBreakerNode {...props} onRemove={onRemove} />
  );
};

export default CircuitBreakerNodeWrapper;
