import React, {memo, useContext, useEffect, useState} from 'react';
import ContentEditable from 'react-contenteditable';

import {Resizable} from 're-resizable';
import sanitizeHtml from 'sanitize-html';
import {useStoreState} from 'react-flow-renderer';

import './TextNode.css';
import {ContextSchema} from "../shared";

const handleClasses = {
  top: "nodrag",
  right: "nodrag",
  bottom: "nodrag",
  left: "nodrag",
  topRight: "nodrag",
  bottomRight: "nodrag",
  bottomLeft: "nodrag",
  topLeft: "nodrag"
};

const TextNode = memo(({ onRemove, data, id }) => {
  const [nodeValue, setNodeValue] = useState(data?.desc ?? "Click to edit");
  const [height, setHeight] = useState(data?.height ?? 90);
  const [width, setWidth] = useState(data?.width ?? 190);
  const [isFocus, setIsFocus] = useState(false);
  const nodes = useStoreState((state) => state.nodes);
  const {elements, setElements} = useContext(ContextSchema);

  useEffect(() => {
    setElements(elements.map((el) => {
      if (el.id === id) {
        el.data["width"] = width;
        el.data["height"] = height;
      }
      return el;
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  const onResizeStop = (e, direction, ref, d) => {
    setHeight((prev) => prev + d.height);
    setWidth((prev) => prev + d.width);
  };

  const handleRemove = () => {
    const el = nodes.find((e) => e.id === id);
    return onRemove([el]);
  };

  const handleOnChange = (e) => {
    const value = e.target.value || 'Click to edit';
    setNodeValue(value);
    setElements(elements.map((el) => {
      if (el.id === id) {
        el.data["desc"] = sanitizeHtml(value, {
          allowedTags: [ 'br', 'div']
        });
      }
      return el;
    }));
  };

  return (
    <Resizable
      className={`resize-text-node${isFocus ? " active" : ""}`}
      size={{ width, height }}
      onResizeStop={onResizeStop}
      handleClasses={handleClasses}
    >
      <div
        className='node-remove'
        onClick={handleRemove}
      >
        X
      </div>
      <ContentEditable
        className='resize-text-node-input-label'
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        html={nodeValue} // innerHTML of the editable div
        disabled={false} // use true to disable editing
        onChange={handleOnChange} // handle innerHTML change
        tagName='div' // Use a custom HTML tag (uses a div by default)
      />

    </Resizable>
  );
});

const TextNodeWrapper = (onRemove) => (props) => {
  return (
    <TextNode {...props} onRemove={onRemove} />
  );
};

export default TextNodeWrapper;
