import React, {memo, useContext, useEffect, useRef, useState} from 'react';

import {Handle, useStoreState} from 'react-flow-renderer';

import './DcDcNode.css';
import {ContextSchema} from "../shared";

const connectorStyle = {
  background: '#fff',
  border: '2px solid black',
  width: '10px',
  height: '10px'
};

const DcDcNode = memo(({ onRemove, data, id, isDragging, isConnectable }) => {
  const [labelStr, setLabelStr] = useState(data?.desc ?? "DC-DC 48v-12v");
  const [editLabel, setEditLabel] = useState(false);
  const inputRef = useRef();
  const nodes = useStoreState((state) => state.nodes);
  const {elements, setElements} = useContext(ContextSchema);

  useEffect(() => {
    if (isDragging && editLabel) {
      setEditLabel(false)
    }
  }, [isDragging, editLabel]);

  return (
    <div className='dcdc-node'>
      <div
        className='node-remove'
        onClick={() => {
          const el = nodes.find((e) => e.id === id);
          return onRemove([el]);
        }}
      >
        X
      </div>

      {
        !editLabel
          ? (
            <label
              className='dcdc-node-input-label'
              onClick={() => {
                setEditLabel(true);
              }}
            >
              {labelStr}
            </label>
          )
          : (
            <input
              className='dcdc-node-input-label'
              type="text"
              ref={inputRef}
              onChange={(e) => {
                setLabelStr(e.target.value);
                setElements(elements.map((el) => {
                  if (el.id === id) {
                    el.data["desc"] = e.target.value;
                  }
                  return el;
                }));
              }}
              onBlur={() => {
                setEditLabel(false);
              }}
              value={labelStr}
            />
          )
      }

      <Handle
        type="source"
        id="a"
        position="bottom"
        style={{ ...connectorStyle, bottom: -10, left: -5 }}
        isConnectable={isConnectable}
      />

      <Handle
        type="source"
        id="b"
        position="bottom"
        style={{ ...connectorStyle, bottom: -10, left: 23 }}
        isConnectable={isConnectable}
      />

      <Handle
        type="source"
        id="c"
        position="bottom"
        style={{ ...connectorStyle, bottom: -10, left: 74 }}
        isConnectable={isConnectable}
      />

      <Handle
        type="source"
        id="d"
        position="bottom"
        style={{ ...connectorStyle, bottom: -10, left: 103 }}
        isConnectable={isConnectable}
      />

    </div>
  );
});

const Switch4NodeWrapper = (onRemove) => (props) => {
  return (
    <DcDcNode {...props} onRemove={onRemove} />
  );
};

export default Switch4NodeWrapper;
