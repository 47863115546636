import React from "react";

import {resolveAfter} from "../shared";
import LogoLong from "../../images/logo-long.png";

export const listWrapper = [
  {
    class: 'panel',
    nodeType: 'panel',
    name: 'Solar Panel'
  },
  {
    class: 'inverter',
    nodeType: 'inverter',
    name: 'Inverter'
  },
  {
    class: 'mppt',
    nodeType: 'mppt',
    name: 'MPPT Controller'
  },
  {
    class: 'pwm',
    nodeType: 'pwm',
    name: 'PWM Controller'
  },
  {
    class: 'solargenerator',
    nodeType: 'solargenerator',
    name: 'Solar Generator'
  },
  {
    class: 'battery',
    nodeType: 'battery',
    name: 'Battery'
  },
  {
    class: 'battery-fuse',
    nodeType: 'batteryfuse',
    name: 'Battery Fuse'
  },
  {
    class: 'electric-breaker',
    nodeType: 'electricbreaker',
    name: 'Electric Breaker'
  },
  {
    class: 'circuit-breaker',
    nodeType: 'circuitbreaker',
    name: 'Circuit Breaker'
  },
  {
    class: 'bus-bar',
    nodeType: 'busbar',
    name: 'Bus Bar'
  },
  {
    class: 'cable-entry-gland',
    nodeType: 'cableentrygland',
    name: 'Cable Entry Gland'
  },
  {
    class: 'ground',
    nodeType: 'ground',
    name: 'Ground'
  },
  {
    class: 'switch',
    nodeType: 'switch',
    name: 'Switch'
  },
  {
    class: 'switch4',
    nodeType: 'switch4',
    name: 'Switch 4 positions'
  },
  {
    class: 'voltage',
    nodeType: 'voltage',
    name: 'Voltage Meter'
  },
  {
    class: 'inlinefuse',
    nodeType: 'inlinefuse',
    name: 'Inline Fuse'
  },
  {
    class: 'dcdc',
    nodeType: 'dcdc',
    name: 'DC-DC Converter'
  },
  {
    class: 'usbload',
    nodeType: 'usbload',
    name: 'USB Load 12V'
  },
  {
    class: 'dcload',
    nodeType: 'dcload',
    name: 'DC Load'
  },
  {
    class: 'acload',
    nodeType: 'acload',
    name: 'AC Load'
  },
  {
    class: 'textbox',
    nodeType: 'resize',
    children: <span>Text</span>,
    name: 'Text Box'
  },
  {
    class: 'text',
    nodeType: 'text',
    children: <span>Text</span>,
    name: 'Textarea'
  },
];

export const creatImage = (setIsLoadingImg) => async (canvas) => {
  // Draw canvas
  const img = new Image();
  img.onload = async () => {
    const canvasPrt = document.createElement('canvas');
    canvasPrt.width = canvas.width;
    canvasPrt.height = canvas.height;
    const ctxPrt = canvasPrt.getContext('2d');

    const image = new Image();
    image.onload = async () => {
      const widthImg = Math.min(300, canvas.width / 4);
      const widthNewCanvas = canvasPrt.width;
      const heightNewCanvas = canvasPrt.height;

      // Draw Canvas
      ctxPrt.drawImage(img, 0, 0, widthNewCanvas, heightNewCanvas);

      // Draw rect
      const heightImg = widthImg*244/996;
      ctxPrt.fillStyle = "#eaeaea";
      ctxPrt.fillRect(0, 0, widthNewCanvas, heightImg + 10);

      // Draw logo
      ctxPrt.drawImage(image, 5, 5, widthImg, heightImg);

      //Draw Text
      ctxPrt.font = "20px Georgia";
      ctxPrt.fillStyle = "#000000";
      ctxPrt.textBaseline = "hanging";
      ctxPrt.fillText(" - Designed with www.solarpaneldiagram.com", widthImg + 25, (heightImg+5) / 2);

      const link = document.createElement('a');
      link.download = 'solar-diagram.png';
      link.href = canvasPrt.toDataURL();
      link.click();

      await resolveAfter(500); // Await for the download to happen

      document.body.appendChild(canvasPrt);
      setIsLoadingImg(false);
    };
    image.src = LogoLong;
  };
  img.src = canvas.toDataURL('image/png');
}
