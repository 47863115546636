import React, { memo } from 'react';

import {Handle, useStoreState} from 'react-flow-renderer';

import './SwitchNode.css';

const connectorStyle = {
  background: '#fff',
  border: '2px solid black',
  width: '10px',
  height: '10px'
};

const SwitchNode = memo(({ onRemove, id, isConnectable }) => {
  const nodes = useStoreState((state) => state.nodes);

  return (
    <div className='switch-node'>
      <div
        className='node-remove'
        onClick={() => {
          const el = nodes.find((e) => e.id === id);
          return onRemove([el]);
        }}
      >
        X
      </div>


      <Handle
        type="source"
        id="a"
        position="top"
        style={{ ...connectorStyle, top: -10, left: 25 }}
        isConnectable={isConnectable}
      />

      <Handle
        type="source"
        id="b"
        position="bottom"
        style={{ ...connectorStyle, bottom: -10, left: 25 }}
        isConnectable={isConnectable}
      />

    </div>
  );
});

const SwitchNodeWrapper = (onRemove) => (props) => {
  return (
    <SwitchNode {...props} onRemove={onRemove} />
  );
};

export default SwitchNodeWrapper;
