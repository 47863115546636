import React from 'react';

import logo from "../../images/logo-icon.png";
import logoTxt from "../../images/logo-text.png";

const MainLogo = () => (
  <div className='side-title'>
    <img
      className='dndflow-logo'
      src={logo}
      width={40}
      height={40}
      draggable={false}
      alt='Solar Panel Diagram'
    />
    <img
      className='dndflow-logo'
      src={logoTxt}
      width={134}
      height={29}
      draggable={false}
      alt='Solar Panel Diagram'
    />
  </div>
);

export default MainLogo;
