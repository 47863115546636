import React from 'react';
import LogoIcon from "../../images/logo-icon.png";

const LoadingScreens = ({isLoadingDiagram, isLoadingPDF, isLoadingImg}) => {
  return (
    <>
      <div className={`dndflow-load${isLoadingDiagram ? " active" : ""}`}>
        <h4>
          <img src={LogoIcon} alt='solar icon' width={60} height={60} />
          {" Loading Diagram..."}
        </h4>
      </div>
      <div className={`dndflow-pdf${isLoadingPDF ? " active" : ""}`}>
        <h4>
          <img src={LogoIcon} alt='solar icon' width={60} height={60} />
          {" Creating PDF..."}
        </h4>
      </div>
      <div className={`dndflow-img${isLoadingImg ? " active" : ""}`}>
        <h4>
          <img src={LogoIcon} alt='solar icon' width={60} height={60} />
          {" Creating the image..."}
        </h4>
      </div>
    </>
  )
}

export default LoadingScreens;
