import React, {memo, useContext, useEffect, useRef, useState} from 'react';

import { Handle, useStoreState } from 'react-flow-renderer';

import './SolarGeneratorNode.css';
import {ContextSchema} from "../shared";

const connectorStyle = {
  background: '#fff',
  border: '2px solid black',
  width: '10px',
  height: '10px'
};

const SolarGeneratorNode = memo(({ onRemove, data, id, isConnectable }) => {
  const [valueLabel, setValueLabel] = useState(data?.desc || 'Solar Generator');
  const [editLabel, setEditLabel] = useState(false);
  const inputRef = useRef();
  const nodes = useStoreState((state) => state.nodes);
  const {elements, setElements} = useContext(ContextSchema);

  useEffect(() => {
    if (editLabel) {
      inputRef.current.focus();
    }
  }, [editLabel]);

  return (
    <div className='solargenerator-node'>
      <div
        className='node-remove'
        onClick={() => {
          const el = nodes.find((e) => e.id === id);
          return onRemove([el]);
        }}
      >
        X
      </div>
      {
        !editLabel
          ? (
            <label
              className='solargenerator-node-input-label'
              onClick={() => {
                setEditLabel(true);
              }}
            >
              {valueLabel}
            </label>
          )
          : (
            <input
              className='solargenerator-node-input-label'
              type="text"
              ref={inputRef}
              onChange={(e) => {
                setValueLabel(e.target.value);
                setElements(elements.map((el) => {
                  if (el.id === id) {
                    el.data["desc"] = e.target.value;
                  }
                  return el;
                }));
              }}
              onBlur={() => {
                setEditLabel(false);
              }}
              value={valueLabel}
            />
          )
      }


      <Handle
        type="source"
        id="a"
        position="left"
        style={{ ...connectorStyle, bottom: 114, left: -14, top: 'auto' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id="b"
        position="left"
        style={{ ...connectorStyle, bottom: 76, left: -14, top: 'auto' }}
        isConnectable={isConnectable}
      />

      <Handle
        type="source"
        id="c"
        position="bottom"
        style={{ ...connectorStyle, left: 51, bottom: 4 }}
        isConnectable={isConnectable}
      />

      <Handle
        type="source"
        id="d"
        position="bottom"
        style={{ ...connectorStyle, left: 73, bottom: 4 }}
        isConnectable={isConnectable}
      />

      <Handle
        type="source"
        id="e"
        position="bottom"
        style={{ ...connectorStyle, left: 96, bottom: 4 }}
        isConnectable={isConnectable}
      />

      <Handle
        type="source"
        position="right"
        id="f"
        style={{ ...connectorStyle, top: 54, right: -11 }}
        isConnectable={isConnectable}
      />

      <Handle
        type="source"
        position="bottom"
        id="g"
        style={{ ...connectorStyle, left: 142, bottom: 4 }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position="bottom"
        id="h"
        style={{ ...connectorStyle, left: 164, bottom: 4 }}
        isConnectable={isConnectable}
      />
    </div>
  );
});

const SolarGeneratorNodeWrapper = (onRemove) => (props) => {
  return (
    <SolarGeneratorNode {...props} onRemove={onRemove} />
  );
}

export default SolarGeneratorNodeWrapper;
