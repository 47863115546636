import React, {useContext, useState} from 'react';
import {
  VscFilePdf,
  FiSave,
  FaBezierCurve,
  AiOutlineFolderOpen,
  BsFileEarmarkPlus,
  AiOutlineFileImage,
  FaProjectDiagram,
  AiFillThunderbolt
} from 'react-icons/all';
import {Row, Col, Nav, NavDropdown} from "react-bootstrap";
import html2PDF from 'jspdf-html2canvas';
import html2canvas from 'html2canvas';

import InputOpenDiagram from "../InputOpenDiagram";

import AboutModal from './AboutModal';
import MainLogo from "./MainLogo";
import {listWrapper, creatImage} from './shared';
import {listDemos, loadJsonDiagram} from "./loadJson";


import {
  iconStyles,
  pdfOptions,
  lineTypes,
  ContextSchema,
  resolveAfter
} from "../shared";
import './Sidebar.css';

const onDragStart = (event, nodeType) => {
  event.dataTransfer.setData('application/reactflow', nodeType);
  event.dataTransfer.effectAllowed = 'move';
};

const Sidebar = ({
  onSave = () => {},
  onCreateNewDiagram = () => {},
  onToggleLine,
  setIsLoadingPDF,
  setIsLoadingImg
}) => {
  const [lineType, setLineType] = useState(lineTypes.smooth);
  const {
    reactFlowInstance,
    setElements,
    onIncrementId,
    setIsLoadingDiagram
  } = useContext(ContextSchema);

  const onToggleLines = () => {
    const newLineType = lineType === lineTypes.smooth ? lineTypes.bezier : lineTypes.smooth;
    setLineType(newLineType);
    onToggleLine(newLineType);
  };

  const onSavePDF = async () => {
    setIsLoadingPDF(true);
    reactFlowInstance.fitView();
    await resolveAfter(500); // Await to fit the view

    const node = document.querySelector(".react-flow__renderer");

    await html2PDF(node, pdfOptions);

    setTimeout(() => {
      setIsLoadingPDF(false);
    }, 500);
  };

  const onSaveImg = async () => {
    setIsLoadingImg(true);
    reactFlowInstance.fitView();
    await resolveAfter(500); // Await to fit the view

    const node = document.querySelector(".react-flow__renderer");

    html2canvas(node)
      .then(creatImage(setIsLoadingImg));
  };

  const loadDemo = async (demoPath) => {
    setIsLoadingDiagram(true);
    const toggleLine = (type) => {
      onToggleLine(type);
      setLineType(type);
    };
    await loadJsonDiagram(demoPath, reactFlowInstance, toggleLine, onIncrementId, setElements);
    setTimeout(() => {
      setIsLoadingDiagram(false);
    }, 500);
  };

  return (
    <>
      <Row as={"aside"} xs={2} sm={2}>
        <MainLogo />

        {
          listWrapper.map((wrap) => (
            <Col className='side-wrapper' key={wrap.nodeType}>
              <div
                className={`react-flow__node-output side-${wrap.class}`}
                onDragStart={(event) => onDragStart(event, wrap.nodeType)}
                draggable
              >
                {wrap.children || null}
              </div>
              <label>{wrap.name}</label>
            </Col>
          ))
        }

        {
          listWrapper.length % 2 !== 0
            ? <Col />
            : null
        }

        <Col />

      </Row>

      <div className='side-options'>

        {/* Create new */}
        <div onClick={onCreateNewDiagram}>
          <BsFileEarmarkPlus style={iconStyles} />{" New"}
        </div>

        {/* Open file */}
        <InputOpenDiagram id='input_bar_file'>
          <AiOutlineFolderOpen style={iconStyles} />{" Open"}
        </InputOpenDiagram>

        {/* Save */}
        <div>
          <Nav>
            <NavDropdown
              id="side-nav-dropdown-save"
              title={<><FiSave style={iconStyles} />{" Save"}</>}
            >
              <NavDropdown.Item href="">
                <div onClick={onSave}>
                  <FiSave style={iconStyles} />{" As File"}
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item href="">
                <div onClick={onSavePDF}>
                  <VscFilePdf style={iconStyles} />{" As PDF"}
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item href="">
                <div onClick={onSaveImg}>
                  <AiOutlineFileImage style={iconStyles} />{" As Image"}
                </div>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </div>

        {/* Toggle smooth path and bezier curve */}
        <div onClick={onToggleLines}>
          <FaBezierCurve style={iconStyles} />{"  Lines"} {lineType}
        </div>

        {/* Load pre made diagrams */}
        <div>
          <Nav>
            <NavDropdown
              id="side-nav-dropdown-save"
              title={<><AiFillThunderbolt style={iconStyles} />{" Examples"}</>}
            >
              {
                Object.keys(listDemos).map((demo) => (
                  <NavDropdown.Item key={demo} href="">
                    <div onClick={() => loadDemo(listDemos[demo])}>
                      <FaProjectDiagram style={iconStyles} />{demo}
                    </div>
                  </NavDropdown.Item>
                ))
              }
            </NavDropdown>
          </Nav>
        </div>

        <AboutModal />
      </div>
    </>
  );
};

export default Sidebar;
