import React, {useState} from 'react';
import {HiOutlineInformationCircle} from "react-icons/all";
import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";

import www from '../../images/www.png';
import insta from '../../images/instagram.png';
import email from '../../images/email.png';
import logo from '../../images/logo-long.png';

const sizeImg = 40;

const renderTooltip = (text) => (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {text}
  </Tooltip>
);

const AboutModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div onClick={handleShow}>
        <HiOutlineInformationCircle style={{height:20, width:20}} /> About
      </div>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{textAlign: 'center', width: '100%'}}>
            <img alt='Solar Panel Diagram' src={logo} width={390} height={96}/>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Solar Panel Diagram is a free diagram tool.
          <br/>
          With Solar Panel Diagram, you can create, share and save
          your diagram for your solar power system.
          <br /><br />
          <div className='social-title'>Find us</div>
          <div className='social-link'>
            <a
              className='social-link-icon'
              href='https://www.solarpowertoolbox.com'
              target='_blank'
              rel='noreferrer'
            >
              <OverlayTrigger
                placement="top"
                delay={{ show: 150, hide: 400 }}
                overlay={renderTooltip(
                  <div className='social-tooltip'>
                    <span>Web</span><br/>
                    <span>www.solarpowertoolbox.com</span>
                  </div>
                )}
              >
                <img
                  src={www}
                  alt='www.solarpowertoolbox.com'
                  width={sizeImg}
                  height={sizeImg}
                />
              </OverlayTrigger>
            </a>

            <a
              className='social-link-icon'
              href='mailto: solarpowertoolbox@gmail.com'
              target='_blank'
              rel='noreferrer'
            >
              <OverlayTrigger
                placement="top"
                delay={{ show: 150, hide: 400 }}
                overlay={renderTooltip(
                  <div className='social-tooltip'>
                    <span>Email</span><br/>
                    <span>solarpowertoolbox@gmail.com</span>
                  </div>
                )}
              >
                <img
                  src={email}
                  alt='solarpowertoolbox@gmail.com'
                  width={sizeImg}
                  height={sizeImg}
                />
              </OverlayTrigger>
            </a>

            <a
              className='social-link-icon'
              href='https://www.instagram.com/solarpowertoolbox'
              target='_blank'
              rel='noreferrer'
            >
              <OverlayTrigger
                placement="top"
                delay={{ show: 150, hide: 400 }}
                overlay={renderTooltip(
                  <div className='social-tooltip'>
                    <span>Instagram</span><br/>
                    <span>@solarpowertoolbox</span>
                  </div>
                )}
              >
                <img
                  src={insta}
                  alt='Solar Power toolbox instagram'
                  width={sizeImg}
                  height={sizeImg}
                />
              </OverlayTrigger>
            </a>

          </div>

        </Modal.Body>
      </Modal>
    </>
  )
};

export default AboutModal;
